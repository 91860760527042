*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
  background-color: #020204;
  color: black;
  box-sizing: border-box;
  overscroll-behavior: contain;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'amatic';
  src: url('/fonts/amatic.ttf');
}

@font-face {
  font-family: 'antonio';
  src: url('/fonts/antonio.ttf');
}

@font-face {
  font-family: 'blowbrush';
  src: url('/fonts/blowbrush.ttf');
}

@font-face {
  font-family: 'chalk';
  src: url('/fonts/chalk.woff');
}

@font-face {
  font-family: 'dafoe';
  src: url('/fonts/dafoe.ttf');
}

@font-face {
  font-family: 'din';
  src: url('/fonts/din.otf');
}

@font-face {
  font-family: 'dolce';
  src: url('/fonts/dolce.ttf');
}

@font-face {
  font-family: 'edo';
  src: url('/fonts/edo.ttf');
}

@font-face {
  font-family: 'futura';
  src: url('/fonts/futura.ttf');
}

@font-face {
  font-family: 'hemi';
  src: url('/fonts/hemi.ttf');
}

@font-face {
  font-family: 'roboto';
  src: url('/fonts/roboto.ttf');
}

@font-face {
  font-family: 'raleway';
  src: url('/fonts/raleway.ttf');
}
